import _transparent from "./caretColor/transparent.mjs"
import _current from "./caretColor/current.mjs"
import _black from "./caretColor/black.mjs"
import _white from "./caretColor/white.mjs"
import _alto from "./caretColor/alto.mjs"
import _dark from "./caretColor/dark.mjs"
import _darkBlue from "./caretColor/darkBlue.mjs"
import _lightGray from "./caretColor/lightGray.mjs"
import _red from "./caretColor/red.mjs"
import _gray from "./caretColor/gray.mjs"
import _cyan from "./caretColor/cyan.mjs"
import _neutral from "./caretColor/neutral.mjs"
import _disabled from "./caretColor/disabled.mjs"
import _primary from "./caretColor/primary.mjs"
const config = { "transparent": _transparent, "current": _current, "black": _black, "white": _white, "alto": _alto, "dark": _dark, "darkBlue": _darkBlue, "lightGray": _lightGray, "red": _red, "gray": _gray, "cyan": _cyan, "neutral": _neutral, "disabled": _disabled, "primary": _primary }
export { config as default, _transparent, _current, _black, _white, _alto, _dark, _darkBlue, _lightGray, _red, _gray, _cyan, _neutral, _disabled, _primary }