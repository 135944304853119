import _transparent from "./borderColor/transparent.mjs"
import _current from "./borderColor/current.mjs"
import _black from "./borderColor/black.mjs"
import _white from "./borderColor/white.mjs"
import _alto from "./borderColor/alto.mjs"
import _dark from "./borderColor/dark.mjs"
import _darkBlue from "./borderColor/darkBlue.mjs"
import _lightGray from "./borderColor/lightGray.mjs"
import _red from "./borderColor/red.mjs"
import _gray from "./borderColor/gray.mjs"
import _cyan from "./borderColor/cyan.mjs"
import _neutral from "./borderColor/neutral.mjs"
import _disabled from "./borderColor/disabled.mjs"
import _primary from "./borderColor/primary.mjs"
import _DEFAULT from "./borderColor/DEFAULT.mjs"
const config = { "transparent": _transparent, "current": _current, "black": _black, "white": _white, "alto": _alto, "dark": _dark, "darkBlue": _darkBlue, "lightGray": _lightGray, "red": _red, "gray": _gray, "cyan": _cyan, "neutral": _neutral, "disabled": _disabled, "primary": _primary, "DEFAULT": _DEFAULT }
export { config as default, _transparent, _current, _black, _white, _alto, _dark, _darkBlue, _lightGray, _red, _gray, _cyan, _neutral, _disabled, _primary, _DEFAULT }