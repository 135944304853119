import _transparent from "./boxShadowColor/transparent.mjs"
import _current from "./boxShadowColor/current.mjs"
import _black from "./boxShadowColor/black.mjs"
import _white from "./boxShadowColor/white.mjs"
import _alto from "./boxShadowColor/alto.mjs"
import _dark from "./boxShadowColor/dark.mjs"
import _darkBlue from "./boxShadowColor/darkBlue.mjs"
import _lightGray from "./boxShadowColor/lightGray.mjs"
import _red from "./boxShadowColor/red.mjs"
import _gray from "./boxShadowColor/gray.mjs"
import _cyan from "./boxShadowColor/cyan.mjs"
import _neutral from "./boxShadowColor/neutral.mjs"
import _disabled from "./boxShadowColor/disabled.mjs"
import _primary from "./boxShadowColor/primary.mjs"
const config = { "transparent": _transparent, "current": _current, "black": _black, "white": _white, "alto": _alto, "dark": _dark, "darkBlue": _darkBlue, "lightGray": _lightGray, "red": _red, "gray": _gray, "cyan": _cyan, "neutral": _neutral, "disabled": _disabled, "primary": _primary }
export { config as default, _transparent, _current, _black, _white, _alto, _dark, _darkBlue, _lightGray, _red, _gray, _cyan, _neutral, _disabled, _primary }