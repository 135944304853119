export default [
  "4.375rem",
  {
    "lineHeight": "0.6857142857142857/* 48px */",
    "fontWeight": 700
  },
  "4.375rem",
  {
    "lineHeight": "0.6857142857142857/* 48px */",
    "fontWeight": 700
  },
  "4.375rem",
  {
    "lineHeight": "0.6857142857142857/* 48px */",
    "fontWeight": 700
  },
  "4.375rem",
  {
    "lineHeight": "0.6857142857142857/* 48px */",
    "fontWeight": 700
  }
]