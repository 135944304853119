import _transparent from "./textColor/transparent.mjs"
import _current from "./textColor/current.mjs"
import _black from "./textColor/black.mjs"
import _white from "./textColor/white.mjs"
import _alto from "./textColor/alto.mjs"
import _dark from "./textColor/dark.mjs"
import _darkBlue from "./textColor/darkBlue.mjs"
import _lightGray from "./textColor/lightGray.mjs"
import _red from "./textColor/red.mjs"
import _gray from "./textColor/gray.mjs"
import _cyan from "./textColor/cyan.mjs"
import _neutral from "./textColor/neutral.mjs"
import _disabled from "./textColor/disabled.mjs"
import _primary from "./textColor/primary.mjs"
const config = { "transparent": _transparent, "current": _current, "black": _black, "white": _white, "alto": _alto, "dark": _dark, "darkBlue": _darkBlue, "lightGray": _lightGray, "red": _red, "gray": _gray, "cyan": _cyan, "neutral": _neutral, "disabled": _disabled, "primary": _primary }
export { config as default, _transparent, _current, _black, _white, _alto, _dark, _darkBlue, _lightGray, _red, _gray, _cyan, _neutral, _disabled, _primary }