export default [
  "Teko",
  "ui-sans-serif",
  "system-ui",
  "Teko",
  "ui-sans-serif",
  "system-ui",
  "Teko",
  "ui-sans-serif",
  "system-ui",
  "Teko",
  "ui-sans-serif",
  "system-ui"
]