export default [
  "3.125rem",
  {
    "lineHeight": "1/* 50px */",
    "fontWeight": 700
  },
  "3.125rem",
  {
    "lineHeight": "1/* 50px */",
    "fontWeight": 700
  },
  "3.125rem",
  {
    "lineHeight": "1/* 50px */",
    "fontWeight": 700
  },
  "3.125rem",
  {
    "lineHeight": "1/* 50px */",
    "fontWeight": 700
  }
]