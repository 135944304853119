import _none from "./fill/none.mjs"
import _transparent from "./fill/transparent.mjs"
import _current from "./fill/current.mjs"
import _black from "./fill/black.mjs"
import _white from "./fill/white.mjs"
import _alto from "./fill/alto.mjs"
import _dark from "./fill/dark.mjs"
import _darkBlue from "./fill/darkBlue.mjs"
import _lightGray from "./fill/lightGray.mjs"
import _red from "./fill/red.mjs"
import _gray from "./fill/gray.mjs"
import _cyan from "./fill/cyan.mjs"
import _neutral from "./fill/neutral.mjs"
import _disabled from "./fill/disabled.mjs"
import _primary from "./fill/primary.mjs"
const config = { "none": _none, "transparent": _transparent, "current": _current, "black": _black, "white": _white, "alto": _alto, "dark": _dark, "darkBlue": _darkBlue, "lightGray": _lightGray, "red": _red, "gray": _gray, "cyan": _cyan, "neutral": _neutral, "disabled": _disabled, "primary": _primary }
export { config as default, _none, _transparent, _current, _black, _white, _alto, _dark, _darkBlue, _lightGray, _red, _gray, _cyan, _neutral, _disabled, _primary }