export default [
  "FatFont-Regular",
  "ui-sans-serif",
  "system-ui",
  "FatFont-Regular",
  "ui-sans-serif",
  "system-ui",
  "FatFont-Regular",
  "ui-sans-serif",
  "system-ui",
  "FatFont-Regular",
  "ui-sans-serif",
  "system-ui"
]