export default [
  "0.875rem",
  {
    "lineHeight": "1.4285714285714286/* 20px */"
  },
  "0.875rem",
  {
    "lineHeight": "1.4285714285714286/* 20px */"
  },
  "0.875rem",
  {
    "lineHeight": "1.4285714285714286/* 20px */"
  },
  "0.875rem",
  {
    "lineHeight": "1.4285714285714286/* 20px */"
  }
]