export default [
  "4.375rem",
  {
    "lineHeight": "1/* 70px */"
  },
  "4.375rem",
  {
    "lineHeight": "1/* 70px */"
  },
  "4.375rem",
  {
    "lineHeight": "1/* 70px */"
  },
  "4.375rem",
  {
    "lineHeight": "1/* 70px */"
  }
]