import _DEFAULT from "./ringColor/DEFAULT.mjs"
import _transparent from "./ringColor/transparent.mjs"
import _current from "./ringColor/current.mjs"
import _black from "./ringColor/black.mjs"
import _white from "./ringColor/white.mjs"
import _alto from "./ringColor/alto.mjs"
import _dark from "./ringColor/dark.mjs"
import _darkBlue from "./ringColor/darkBlue.mjs"
import _lightGray from "./ringColor/lightGray.mjs"
import _red from "./ringColor/red.mjs"
import _gray from "./ringColor/gray.mjs"
import _cyan from "./ringColor/cyan.mjs"
import _neutral from "./ringColor/neutral.mjs"
import _disabled from "./ringColor/disabled.mjs"
import _primary from "./ringColor/primary.mjs"
const config = { "DEFAULT": _DEFAULT, "transparent": _transparent, "current": _current, "black": _black, "white": _white, "alto": _alto, "dark": _dark, "darkBlue": _darkBlue, "lightGray": _lightGray, "red": _red, "gray": _gray, "cyan": _cyan, "neutral": _neutral, "disabled": _disabled, "primary": _primary }
export { config as default, _DEFAULT, _transparent, _current, _black, _white, _alto, _dark, _darkBlue, _lightGray, _red, _gray, _cyan, _neutral, _disabled, _primary }