import _transparent from "./divideColor/transparent.mjs"
import _current from "./divideColor/current.mjs"
import _black from "./divideColor/black.mjs"
import _white from "./divideColor/white.mjs"
import _alto from "./divideColor/alto.mjs"
import _dark from "./divideColor/dark.mjs"
import _darkBlue from "./divideColor/darkBlue.mjs"
import _lightGray from "./divideColor/lightGray.mjs"
import _red from "./divideColor/red.mjs"
import _gray from "./divideColor/gray.mjs"
import _cyan from "./divideColor/cyan.mjs"
import _neutral from "./divideColor/neutral.mjs"
import _disabled from "./divideColor/disabled.mjs"
import _primary from "./divideColor/primary.mjs"
import _DEFAULT from "./divideColor/DEFAULT.mjs"
const config = { "transparent": _transparent, "current": _current, "black": _black, "white": _white, "alto": _alto, "dark": _dark, "darkBlue": _darkBlue, "lightGray": _lightGray, "red": _red, "gray": _gray, "cyan": _cyan, "neutral": _neutral, "disabled": _disabled, "primary": _primary, "DEFAULT": _DEFAULT }
export { config as default, _transparent, _current, _black, _white, _alto, _dark, _darkBlue, _lightGray, _red, _gray, _cyan, _neutral, _disabled, _primary, _DEFAULT }