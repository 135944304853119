import _xs from "./fontSize/xs.mjs"
import _sm from "./fontSize/sm.mjs"
import _body from "./fontSize/body.mjs"
import _bodyBig from "./fontSize/bodyBig.mjs"
import _bigText from "./fontSize/bigText.mjs"
import _subTech1 from "./fontSize/subTech1.mjs"
import _subTech from "./fontSize/subTech.mjs"
import _subTechBig from "./fontSize/subTechBig.mjs"
import _sub1 from "./fontSize/sub1.mjs"
import _sub from "./fontSize/sub.mjs"
import _head from "./fontSize/head.mjs"
import _headBig from "./fontSize/headBig.mjs"
import _fatBigMobile from "./fontSize/fatBigMobile.mjs"
import _fatBig from "./fontSize/fatBig.mjs"
import _numbers from "./fontSize/numbers.mjs"
const config = { "xs": _xs, "sm": _sm, "body": _body, "bodyBig": _bodyBig, "bigText": _bigText, "subTech1": _subTech1, "subTech": _subTech, "subTechBig": _subTechBig, "sub1": _sub1, "sub": _sub, "head": _head, "headBig": _headBig, "fatBigMobile": _fatBigMobile, "fatBig": _fatBig, "numbers": _numbers }
export { config as default, _xs, _sm, _body, _bodyBig, _bigText, _subTech1, _subTech, _subTechBig, _sub1, _sub, _head, _headBig, _fatBigMobile, _fatBig, _numbers }