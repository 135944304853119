export default [
  "1.5625rem",
  {
    "lineHeight": "1.16/* 29px */",
    "fontWeight": 600
  },
  "1.5625rem",
  {
    "lineHeight": "1.16/* 29px */",
    "fontWeight": 600
  },
  "1.5625rem",
  {
    "lineHeight": "1.16/* 29px */",
    "fontWeight": 600
  },
  "1.5625rem",
  {
    "lineHeight": "1.16/* 29px */",
    "fontWeight": 600
  }
]