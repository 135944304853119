export default [
  "8.75rem",
  {
    "lineHeight": "0.7785714285714286/* 109px */"
  },
  "8.75rem",
  {
    "lineHeight": "0.7785714285714286/* 109px */"
  },
  "8.75rem",
  {
    "lineHeight": "0.7785714285714286/* 109px */"
  },
  "8.75rem",
  {
    "lineHeight": "0.7785714285714286/* 109px */"
  }
]