export default [
  "1.25rem",
  {
    "lineHeight": "1.25/* 25px */"
  },
  "1.25rem",
  {
    "lineHeight": "1.25/* 25px */"
  },
  "1.25rem",
  {
    "lineHeight": "1.25/* 25px */"
  },
  "1.25rem",
  {
    "lineHeight": "1.25/* 25px */"
  }
]