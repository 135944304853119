export default [
  "1rem",
  {
    "lineHeight": "1.4375/* 23px */"
  },
  "1rem",
  {
    "lineHeight": "1.4375/* 23px */"
  },
  "1rem",
  {
    "lineHeight": "1.4375/* 23px */"
  },
  "1rem",
  {
    "lineHeight": "1.4375/* 23px */"
  }
]