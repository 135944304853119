import _sm from "./screens/sm.mjs"
import _md from "./screens/md.mjs"
import _lg from "./screens/lg.mjs"
import _xl from "./screens/xl.mjs"
import _2xl from "./screens/2xl.mjs"
import _landscape from "./screens/landscape.mjs"
import _hd from "./screens/hd.mjs"
import _narrow from "./screens/narrow.mjs"
import _xs from "./screens/xs.mjs"
const config = { "sm": _sm, "md": _md, "lg": _lg, "xl": _xl, "2xl": _2xl, "landscape": _landscape, "hd": _hd, "narrow": _narrow, "xs": _xs }
export { config as default, _sm, _md, _lg, _xl, _2xl, _landscape, _hd, _narrow, _xs }