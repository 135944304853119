export default [
  "0.75rem",
  {
    "lineHeight": "1.3333333333333333/* 16px */"
  },
  "0.75rem",
  {
    "lineHeight": "1.3333333333333333/* 16px */"
  },
  "0.75rem",
  {
    "lineHeight": "1.3333333333333333/* 16px */"
  },
  "0.75rem",
  {
    "lineHeight": "1.3333333333333333/* 16px */"
  }
]