export default [
  "Inter",
  "ui-sans-serif",
  "system-ui",
  "Inter",
  "ui-sans-serif",
  "system-ui",
  "Inter",
  "ui-sans-serif",
  "system-ui",
  "Inter",
  "ui-sans-serif",
  "system-ui"
]