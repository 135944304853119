import _none from "./stroke/none.mjs"
import _transparent from "./stroke/transparent.mjs"
import _current from "./stroke/current.mjs"
import _black from "./stroke/black.mjs"
import _white from "./stroke/white.mjs"
import _alto from "./stroke/alto.mjs"
import _dark from "./stroke/dark.mjs"
import _darkBlue from "./stroke/darkBlue.mjs"
import _lightGray from "./stroke/lightGray.mjs"
import _red from "./stroke/red.mjs"
import _gray from "./stroke/gray.mjs"
import _cyan from "./stroke/cyan.mjs"
import _neutral from "./stroke/neutral.mjs"
import _disabled from "./stroke/disabled.mjs"
import _primary from "./stroke/primary.mjs"
const config = { "none": _none, "transparent": _transparent, "current": _current, "black": _black, "white": _white, "alto": _alto, "dark": _dark, "darkBlue": _darkBlue, "lightGray": _lightGray, "red": _red, "gray": _gray, "cyan": _cyan, "neutral": _neutral, "disabled": _disabled, "primary": _primary }
export { config as default, _none, _transparent, _current, _black, _white, _alto, _dark, _darkBlue, _lightGray, _red, _gray, _cyan, _neutral, _disabled, _primary }