const _0 = "0px"
const _1 = "0.25rem"
const _2 = "0.5rem"
const _3 = "0.75rem"
const _4 = "1rem"
const _5 = "1.25rem"
const _6 = "1.5rem"
const _7 = "1.75rem"
const _8 = "2rem"
const _9 = "2.25rem"
const _10 = "2.5rem"
const _11 = "2.75rem"
const _12 = "3rem"
const _13 = "3.25rem"
const _14 = "3.5rem"
const _15 = "3.75rem"
const _16 = "4rem"
const _17 = "4.25rem"
const _18 = "4.5rem"
const _20 = "5rem"
const _24 = "6rem"
const _28 = "7rem"
const _30 = "7.5rem"
const _32 = "8rem"
const _36 = "9rem"
const _40 = "10rem"
const _44 = "11rem"
const _48 = "12rem"
const _50 = "12.5rem"
const _52 = "13rem"
const _56 = "14rem"
const _60 = "15rem"
const _64 = "16rem"
const _72 = "18rem"
const _80 = "20rem"
const _96 = "24rem"
const _auto = "auto"
const _px = "1px"
const _full = "100%"
const config = { "0": _0, "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "11": _11, "12": _12, "13": _13, "14": _14, "15": _15, "16": _16, "17": _17, "18": _18, "20": _20, "24": _24, "28": _28, "30": _30, "32": _32, "36": _36, "40": _40, "44": _44, "48": _48, "50": _50, "52": _52, "56": _56, "60": _60, "64": _64, "72": _72, "80": _80, "96": _96, "auto": _auto, "px": _px, "full": _full, "0.5": "0.125rem", "1.5": "0.375rem", "2.5": "0.625rem", "3.5": "0.875rem", "1/2": "50%", "1/3": "33.333333%", "2/3": "66.666667%", "1/4": "25%", "2/4": "50%", "3/4": "75%", "1/5": "20%", "2/5": "40%", "3/5": "60%", "4/5": "80%", "1/6": "16.666667%", "2/6": "33.333333%", "3/6": "50%", "4/6": "66.666667%", "5/6": "83.333333%", "1/12": "8.333333%", "2/12": "16.666667%", "3/12": "25%", "4/12": "33.333333%", "5/12": "41.666667%", "6/12": "50%", "7/12": "58.333333%", "8/12": "66.666667%", "9/12": "75%", "10/12": "83.333333%", "11/12": "91.666667%",  }
export { config as default, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _20, _24, _28, _30, _32, _36, _40, _44, _48, _50, _52, _56, _60, _64, _72, _80, _96, _auto, _px, _full }