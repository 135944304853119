export default [
  "1.5rem",
  {
    "lineHeight": "1.1666666666666667/* 28px */",
    "fontWeight": 700
  },
  "1.5rem",
  {
    "lineHeight": "1.1666666666666667/* 28px */",
    "fontWeight": 700
  },
  "1.5rem",
  {
    "lineHeight": "1.1666666666666667/* 28px */",
    "fontWeight": 700
  },
  "1.5rem",
  {
    "lineHeight": "1.1666666666666667/* 28px */",
    "fontWeight": 700
  }
]