export default [
  "14.75rem",
  {
    "lineHeight": "0.6606060606060606/* 109px */",
    "fontWeight": 300
  },
  "14.75rem",
  {
    "lineHeight": "0.6606060606060606/* 109px */",
    "fontWeight": 300
  },
  "14.75rem",
  {
    "lineHeight": "0.6606060606060606/* 109px */",
    "fontWeight": 300
  },
  "14.75rem",
  {
    "lineHeight": "0.6606060606060606/* 109px */",
    "fontWeight": 300
  }
]