export default [
  "1.875rem",
  {
    "lineHeight": "1.1333333333333333/* 34px */",
    "fontWeight": 700
  },
  "1.875rem",
  {
    "lineHeight": "1.1333333333333333/* 34px */",
    "fontWeight": 700
  },
  "1.875rem",
  {
    "lineHeight": "1.1333333333333333/* 34px */",
    "fontWeight": 700
  },
  "1.875rem",
  {
    "lineHeight": "1.1333333333333333/* 34px */",
    "fontWeight": 700
  }
]