import _transparent from "./textDecorationColor/transparent.mjs"
import _current from "./textDecorationColor/current.mjs"
import _black from "./textDecorationColor/black.mjs"
import _white from "./textDecorationColor/white.mjs"
import _alto from "./textDecorationColor/alto.mjs"
import _dark from "./textDecorationColor/dark.mjs"
import _darkBlue from "./textDecorationColor/darkBlue.mjs"
import _lightGray from "./textDecorationColor/lightGray.mjs"
import _red from "./textDecorationColor/red.mjs"
import _gray from "./textDecorationColor/gray.mjs"
import _cyan from "./textDecorationColor/cyan.mjs"
import _neutral from "./textDecorationColor/neutral.mjs"
import _disabled from "./textDecorationColor/disabled.mjs"
import _primary from "./textDecorationColor/primary.mjs"
const config = { "transparent": _transparent, "current": _current, "black": _black, "white": _white, "alto": _alto, "dark": _dark, "darkBlue": _darkBlue, "lightGray": _lightGray, "red": _red, "gray": _gray, "cyan": _cyan, "neutral": _neutral, "disabled": _disabled, "primary": _primary }
export { config as default, _transparent, _current, _black, _white, _alto, _dark, _darkBlue, _lightGray, _red, _gray, _cyan, _neutral, _disabled, _primary }