export default [
  "1.5625rem",
  {
    "lineHeight": "1.2/* 30px */"
  },
  "1.5625rem",
  {
    "lineHeight": "1.2/* 30px */"
  },
  "1.5625rem",
  {
    "lineHeight": "1.2/* 30px */"
  },
  "1.5625rem",
  {
    "lineHeight": "1.2/* 30px */"
  }
]