export default [
  "2.1875rem",
  {
    "lineHeight": "1.0857142857142856/* 38px */",
    "fontWeight": 600
  },
  "2.1875rem",
  {
    "lineHeight": "1.0857142857142856/* 38px */",
    "fontWeight": 600
  },
  "2.1875rem",
  {
    "lineHeight": "1.0857142857142856/* 38px */",
    "fontWeight": 600
  },
  "2.1875rem",
  {
    "lineHeight": "1.0857142857142856/* 38px */",
    "fontWeight": 600
  }
]