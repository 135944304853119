import _transparent from "./outlineColor/transparent.mjs"
import _current from "./outlineColor/current.mjs"
import _black from "./outlineColor/black.mjs"
import _white from "./outlineColor/white.mjs"
import _alto from "./outlineColor/alto.mjs"
import _dark from "./outlineColor/dark.mjs"
import _darkBlue from "./outlineColor/darkBlue.mjs"
import _lightGray from "./outlineColor/lightGray.mjs"
import _red from "./outlineColor/red.mjs"
import _gray from "./outlineColor/gray.mjs"
import _cyan from "./outlineColor/cyan.mjs"
import _neutral from "./outlineColor/neutral.mjs"
import _disabled from "./outlineColor/disabled.mjs"
import _primary from "./outlineColor/primary.mjs"
const config = { "transparent": _transparent, "current": _current, "black": _black, "white": _white, "alto": _alto, "dark": _dark, "darkBlue": _darkBlue, "lightGray": _lightGray, "red": _red, "gray": _gray, "cyan": _cyan, "neutral": _neutral, "disabled": _disabled, "primary": _primary }
export { config as default, _transparent, _current, _black, _white, _alto, _dark, _darkBlue, _lightGray, _red, _gray, _cyan, _neutral, _disabled, _primary }